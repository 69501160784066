/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  orderId
  ticketId
  status
  latestInvoiceStatus
  type
  plannedAt
  customerName
  customerErpId
  shipToName
  address
  source
  plannedGal
  startAt
  endAt
  createdAtTimestamp
  productNames
  driverName
  driverEmail
  loadingReceipts{
  receiptNumber
  }
  driverId
  deliveryTruckNames
  createdBy
  assignedBy
  driverAcceptance
  deliveredGal
  linkedOrders
  mainOrderGroupId
  mainOrderGroupTenant
  coordinates {
    lat
    lng
  }
  driverGroupName
  priority
  preferredSupplierNames
  supplierNames
  hubName
  state
  zip
  city
  deliveredQty
  plannedQty
  poNumber
  purchaseOrderIsRequired
  purchaseOrderType
  carrierName
  carrierId
  `;

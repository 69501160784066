/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  ticketId
  createdAtTimestamp
  startOnTimestamp
  departedOnTimestamp
  startedOnTimestamp
  completedOnTimestamp
  completeOnTimestamp
  status
  latestInvoiceStatus
  driverAcceptance
  priority
  creatable{
    __typename
    ... on User{
      id
      name
    }
    ... on Driver {
      id
      name
    }
  }
  assignable{
    __typename
    ... on User{
      id
      name
    }
    ... on Driver {
      id
      name
    }
  }
  worker {
    id
    name
  }
  deliveryOrders {
    id
    status
    orderLinks {
      id
      deliveryOrder {
        id
      }
      loadingOrder {
        id
        orderGroupId
      }
    }
    orderNumber
    deliveryInstruction
    source
    customerBranch {
      id
      name
      address
      site {
        lat
        lng
        radius
      }
      customer {
        id
        name
        address
      }
    }
    deliveryOrderLineItems {
      id
      requestedVolume
      volume
      topOff
      product{
        id
        name
        unit
      }
      sourceCompartments {
        compartment {
          asset {
            id
            licensePlateNumber
            name
          }
        }
      }
      subscription {
        id
        product {
          id
          name
        },
        asset {
          id
          licensePlateNumber
          name
          category
        }
      }
    }
    linkedOrders {
      id
    }
  }
  loadingOrders {
    id
    status
    terminal {
      id
      name
      site {
        lat
        lng
        radius
        address
      }
      suppliers {
        id
        name
        loadingAccounts {
          id
          carrierCustomerCode
          loadingNumber
        }
      }

    }
    orderLinks {
      id
      deliveryOrder {
        id
        orderGroupId
      }
      loadingOrder {
        id
      }
    }
    loadingReceipts {
      id
      loadingAccount {
        id
        loadingNumber
        carrierCustomerCode
        supplier {
          id
          name
        }
      }
    }
    loadingOrderProducts {
      id
      preferredSuppliers {
        id
        loadingAccount {
          id
          loadingNumber
          carrierCustomerCode
          supplier {
            id
            name
          }
        }
      }
      product {
        name
        id
        unit
      }
      grossQuantity
    }
    lineItems {
      compartment {
        asset {
          id
          licensePlateNumber
          name
        }
      }
    }
    linkedOrders {
      id
    }
  }
  transferOrder {
    id
    transferDateTimestamp
    notes
    orderGroupId
    fromDriver {
      name
    }
    fromAsset {
      name
      licensePlateNumber
    }
    toDriver {
      name
    }
    toAsset {
      name
      licensePlateNumber
    }
    lineItems {
      id
      fromCompartment {
        name
      }
      toCompartment {
        name
      }
      product {
        name
      }
      volume
      requestedVolume
    }
  }
  shiftId
  shift {
    shiftDeliveryUnitsAssets{
      deliveryAsset {
        name
        licensePlateNumber
      }
    }
  }
`;
